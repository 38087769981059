import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

import Card from '../components/Card';

import pic1 from '../assets/images/event/slideshow/1.jpg';

const EventPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Les concerts</h2>
          <p>Des scènes en musique à part</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Les concerts</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue <b><Link to="/artistes#concerts">les artistes</Link></b>. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. </p>
          <h3 className="major">À venir</h3>

          <section className="features">

            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Concert d'AKM à Bercy" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            

          </section>
          
          <section className="footer">
            <button className="small">Voir les concerts passés</button>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default EventPage;
